import React from 'react';

import Layout from '../components/layouts/layout.component';

const PAGE_TITLE = 'Blog';

const CatalogoPage = ({ location, pageContext}) => {

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
    >
      <h1>Blog</h1>
    </Layout>
  );
};

export default CatalogoPage;
